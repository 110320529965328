<template>
  <div>
    <div class="flex mt-4" :class="{ 'has-fields': customFieldValue.length > 0 }">
      <vs-button v-round size="medium" class="primary mb-2 only-border-btn" @click="createCustomField">
        <plus-icon size="1.5x" class="button-icon"></plus-icon>
        Create custom field
      </vs-button>
    </div>
    <!-- custom fields list card starts here -->
    <vs-row class="mt-5">
      <vs-card class="customFieldCard lg:p-5 md:p-3 p-2" v-for="(customField, indextr) in customFieldsList" :key="indextr">
        <div class="customFieldCard__header">
          <div class="flex justify-between">
            <vs-card class="blueCard mb-0">
              <span class="text-white flex items-center px-2 py-0.5">
                <img :src="datepicker" alt="date" v-if="customField.type == 'date'" />
                <img :src="currency" alt="currency" v-if="customField.type == 'currency'" />
                <img :src="dropdown" alt="dropdown" v-if="customField.type == 'dropdown'" />
                <img :src="text" alt="text" v-if="customField.type == 'text'" />
                <img :src="number" alt="number" v-if="customField.type == 'number'" />
                <img :src="fileupload" alt="fileupload" v-if="customField.type == 'file'" />
                <span class="uppercase text-white ml-2">{{ customField.type }}</span>
              </span>
            </vs-card>

            <a @click="openEditGroup(customField, indextr)" class="pl-5"><img :src="edit" alt="edit" /></a>
          </div>
          <div class="w-full">
            <h1 class="font-medium text-xl mt-4">{{ customField.label }}</h1>
            <span class="customFieldId">{{ customField.customFieldId }}</span>
          </div>
        </div>
      </vs-card>
    </vs-row>
    <!-- custom fields list card ends here -->
    <!-- custom field create popup form starts here-->
    <vs-popup id="halomundo" class="holamundo req-template-options" title="New additional information field" :active.sync="popupActivo" :key="randomKey">
      <vs-row>
        <p class="headerParagraph mb-5">Any fields you create will be visible to all users and can be added to any payment page. You can edit or remove
          additional information fields in Settings > Payment pages > Custom fields.
        </p>
      </vs-row>
      <form>
        <div class="my-5">
          <vs-row class="flex flex-col">
            <label class="font-light text-base mr-4">Field name</label>
            <vs-input class="w-1/2 arrayInput" size="large" v-model="label" name="label" @keyup="handleLabel" />
          </vs-row>
          <span class="text-danger text-sm" v-show="errors.has('label')">{{ errors.first("label") }}</span>
        </div>

        <vs-row class="mt-10 mb-4">
          <h1 class="font-light text-base">Field type</h1>
        </vs-row>
        <!-- radio button first row starts here -->
        <vs-row class="flex-wrap justify-start">
          <!-- text -->
          <div class="radioButtonCard lg:mr-4 md:mr-4 w-auto">
            <div class="radio-card mt-5" @click="activate('text')" :class="{ active: typeForCss == 'text' }">
              <vs-row>
                <vs-col vs-w="10" vs-sm="10" vs-lg="10" vs-xs="10" class="radio-label">Text</vs-col>
                <vs-col vs-w="2" vs-sm="2" vs-lg="2" vs-xs="2">
                  <vs-radio v-model="type" vs-value="text" vs-name="type"></vs-radio>
                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                  <div class="h-10">
                    <span class="radio-info">Enter any combination of letters, numbers, and special characters</span>
                  </div>
                </vs-col>
              </vs-row>
            </div>
          </div>

          <!-- number -->
          <div class="radioButtonCard lg:mr-4 md:mr-4 w-auto">
            <div class="radio-card mt-5" @click="activate('number')" :class="{ active: typeForCss == 'number' }">
              <vs-row>
                <vs-col vs-w="10" vs-sm="10" vs-lg="10" vs-xs="10" class="radio-label">Number</vs-col>
                <vs-col vs-w="2" vs-sm="2" vs-lg="2" vs-xs="2">
                  <vs-radio v-model="type" vs-value="number" vs-name="type"></vs-radio>
                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                  <div class="h-10">
                    <span class="radio-info">Enter value made up of numbers only (decimals allowed)</span>
                  </div>
                </vs-col>
              </vs-row>
            </div>
          </div>

          <!-- currency -->
          <div class="radioButtonCard">
            <div class="radio-card mt-5" @click="activate('currency')" :class="{ active: typeForCss == 'currency' }">
              <vs-row>
                <vs-col vs-w="10" vs-sm="10" vs-lg="10" vs-xs="10" class="radio-label">Currency</vs-col>
                <vs-col vs-w="2" vs-sm="2" vs-lg="2" vs-xs="2">
                  <vs-radio v-model="type" vs-value="currency" vs-name="type"></vs-radio>
                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                  <div class="h-10">
                    <span class="radio-info">Enter numerical, two decimal amount i.e. dollar amount</span>
                  </div>
                </vs-col>
              </vs-row>
            </div>
          </div>
        </vs-row>
        <!-- radio button first row ends here -->
        <!-- radio button second row starts here -->
        <vs-row class="mb-10 flex-wrap justify-start">
          <!-- date -->
          <div class="radioButtonCard lg:mr-4 md:mr-4 w-auto">
            <div class="radio-card mt-5" @click="activate('date')" :class="{ active: typeForCss == 'date' }">
              <vs-row>
                <vs-col vs-w="10" vs-sm="10" vs-lg="10" vs-xs="10" class="radio-label">Date</vs-col>
                <vs-col vs-w="2" vs-sm="2" vs-lg="2" vs-xs="2">
                  <vs-radio v-model="type" vs-value="date" vs-name="type"></vs-radio>
                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                  <div class="h-10">
                    <span class="radio-info">Enter date in DD/MM/YYYY format</span>
                  </div>
                </vs-col>
              </vs-row>
            </div>
          </div>

          <!-- dropdown -->
          <div class="radioButtonCard lg:mr-4 md:mr-4 w-auto">
            <div class="radio-card mt-5" @click="activate('dropdown')" :class="{ active: typeForCss == 'dropdown' }">
              <vs-row>
                <vs-col vs-w="10" vs-sm="10" vs-lg="10" vs-xs="10" class="radio-label">Dropdown</vs-col>
                <vs-col vs-w="2" vs-sm="2" vs-lg="2" vs-xs="2">
                  <vs-radio v-model="type" vs-value="dropdown" vs-name="type"></vs-radio>
                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                  <div class="h-10">
                    <span class="radio-info">Select a value from a predefined list of options</span>
                  </div>
                </vs-col>
              </vs-row>
            </div>
          </div>

          <!-- file upload -->
          <div class="radioButtonCard lg:mr-4 md:mr-4 w-auto">
            <div class="radio-card mt-5" @click="activate('file')" :class="{ active: typeForCss == 'file' }">
              <vs-row>
                <vs-col vs-w="10" vs-sm="10" vs-lg="10" vs-xs="10" class="radio-label">File Upload</vs-col>
                <vs-col vs-w="2" vs-sm="2" vs-lg="2" vs-xs="2">
                  <vs-radio v-model="type" vs-value="file" vs-name="type"></vs-radio>
                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                  <div class="h-10">
                    <span class="radio-info">Upload and submit a PDF file</span>
                  </div>
                </vs-col>
              </vs-row>
            </div>
          </div>
        </vs-row>
        <!-- radio button second row ends here -->

        <div v-if="type == 'dropdown'">
          <vs-row class="mt-5 mb-8">
            <h3 class="text-xl font-normal">Configure dropdown</h3>
            <hr class="mt-3 mb-2" />
          </vs-row>
          <vs-row>
            <vs-col vs-w="6">
              <p class="mb-4">Add the options you want to appear in the dropdown.</p>
              <!-- array form starts here -->
              <div class="configureDropdown">
                <vs-row class="mb-4" v-for="(m, index) in dropdownSelectFields" :key="index">
                  <vs-col vs-w="3" vs-xs="12" class="pr-6 w-4/5">
                    <div class="arrayInput">
                      <vs-input class="w-full" size="large" v-validate="{ required: (type = 'dropdown') }" v-model="option[index]" :name="`option[${index}]`" />
                    </div>
                  </vs-col>
                  <vs-col vs-w="3" vs-xs="12" class="flex items-end justify-center w-1/5">
                    <div>
                      <a class="flex" @click="deleteDropdownField(m, index)">
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M2.5 5.5H4.16667H17.5" stroke="#828282" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          <path
                            d="M6.6665 5.50002V3.83335C6.6665 3.39133 6.8421 2.9674 7.15466 2.65484C7.46722 2.34228 7.89114 2.16669 8.33317 2.16669H11.6665C12.1085 2.16669 12.5325 2.34228 12.845 2.65484C13.1576 2.9674 13.3332 3.39133 13.3332 3.83335V5.50002M15.8332 5.50002V17.1667C15.8332 17.6087 15.6576 18.0326 15.345 18.3452C15.0325 18.6578 14.6085 18.8334 14.1665 18.8334H5.83317C5.39114 18.8334 4.96722 18.6578 4.65466 18.3452C4.3421 18.0326 4.1665 17.6087 4.1665 17.1667V5.50002H15.8332Z"
                            stroke="#828282"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path d="M8.3335 9.66669V14.6667" stroke="#828282" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M11.6665 9.66669V14.6667" stroke="#828282" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </a>
                    </div>
                  </vs-col>
                </vs-row>
                <vs-button v-round size="medium" @click="addDropdownOptions()" class="mt-5 only-border-btn addDropdownOptions">
                  <plus-icon size="1.5x" class="button-icon"></plus-icon>
                  Add Option
                </vs-button>
              </div>

              <!-- array form ends here -->
            </vs-col>
            <vs-col vs-w="6">
              <h3 class="text-xl font-normal">Preview</h3>
              <vs-card class="previewCard">
                <!-- if dropdown -->
                <vs-row class="w-full flex flex-col">
                  <label for="dropdown" class="previewLabel">{{ label }}</label>
                  <select
                    class="w-full"
                    name="selectOption"
                    id="dropdown"
                    style="border: none; background: none; margin-top: 5px; padding-bottom: 5px; border-bottom: 1px solid #bdbdbd;"
                  >
                    <option v-for="(selectOption, index) in option" :key="index" :value="selectOption">{{ selectOption }}</option>
                  </select>
                </vs-row>
              </vs-card>
            </vs-col>
          </vs-row>
        </div>
      </form>

      <!-- preview area starts here-->

      <div class="mt-5" v-if="type != 'dropdown'">
        <h3 class="text-lg font-normal">Preview</h3>
        <vs-card class="previewCard">
          <!-- if not dropdown -->
          <vs-row class="w-full flex flex-col">
            <label class="previewLabel">{{ label }}</label>
            <div class="flex relative">
              <span class="text-base flex items-end" v-if="type == 'currency'">$</span>
              <input type="file" class="hidden" v-if="type == 'file'" ref="uploadImgInput" accept="image/png" />
              <vs-button v-if="type == 'file'" v-round size="medium" @click="$refs.uploadImgInput.click()" class="only-border-btn mt-0" style="width: min-content">
                Upload file
              </vs-button>
              <vs-input :type="type" v-if="type != 'date' && type != 'file'" class="previewInput w-full" required />
              <date-picker
                :type="type"
                v-if="type == 'date'"
                v-model="datepickerPreview"
                placeholder="mm/dd/yyyy"
                input-class="date-picker-input w-full previewInput"
                format="MM/DD/YYYY"
                valuetype="format"
                lang="jp"
              ></date-picker>
            </div>
          </vs-row>
        </vs-card>
      </div>
      <!-- preview area ends here-->
      <!-----field configuration starts here----->
      <h4 class="pt-4 text-xl font-normal">Field configuration</h4>
      <vs-divider class="my-2"></vs-divider>

      <div class="grid grid-cols-3 gap-2 my-10">
        <div>
          <div class="flex items-center mb-4">
            <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10.0586 0.558579C10.0211 0.521071 9.9702 0.5 9.91716 0.5H2C0.9 0.5 0 1.4 0 2.5V18.5C0 19.6 0.9 20.5 2 20.5H14C15.1 20.5 16 19.6 16 18.5V6.58284C16 6.5298 15.9789 6.47893 15.9414 6.44142L10.0586 0.558579ZM2.2 18.5C2.08954 18.5 2 18.4105 2 18.3V2.7C2 2.58954 2.08954 2.5 2.2 2.5H8.8C8.91046 2.5 9 2.58954 9 2.7V6.3C9 6.41046 9.08954 6.5 9.2 6.5H13.8C13.9105 6.5 14 6.58954 14 6.7V18.3C14 18.4105 13.9105 18.5 13.8 18.5H2.2Z"
                fill="#757575"
              />
              <path d="M7.90918 8.5V15.5" stroke="#757575" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M9.5 9.77277H7.11364C6.81828 9.77277 6.53502 9.8901 6.32618 10.0989C6.11733 10.3078 6 10.591 6 10.8864C6 11.1818 6.11733 11.465 6.32618 11.6739C6.53502 11.8827 6.81828 12 7.11364 12H8.70455C8.9999 12 9.28316 12.1174 9.49201 12.3262C9.70085 12.5351 9.81818 12.8183 9.81818 13.1137C9.81818 13.409 9.70085 13.6923 9.49201 13.9011C9.28316 14.11 8.9999 14.2273 8.70455 14.2273H6"
                stroke="#757575"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span class="ml-2">When sending requests</span>
          </div>
          <vs-row class="mb-2">
            <vs-checkbox
              class="text-gray-font mx-0 text-base checkbox-space"
              v-model="customField.editableByMerchant"
              :vs-value="true"
              :disabled="customField.reqToSendRequest"
              @change="changeCheckboxValue(customField)"
              >Editable by merchant</vs-checkbox
            >
          </vs-row>
          <vs-row class="mb-2">
            <vs-checkbox
              class="text-gray-font mx-0 text-base checkbox-space"
              v-model="customField.reqToSendRequest"
              :vs-value="true"
              @change="changeCheckboxValue(customField)"
              >Required to send request</vs-checkbox
            >
          </vs-row>
        </div>

        <div>
          <div class="flex items-center mb-4">
            <svg width="25" height="22" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10.3332 15.125C10.4943 15.125 10.6248 14.9944 10.6248 14.8334C10.6248 14.6723 10.4943 14.5417 10.3332 14.5417C10.1721 14.5417 10.0415 14.6723 10.0415 14.8334C10.0415 14.9944 10.1721 15.125 10.3332 15.125Z"
                stroke="#757575"
                stroke-width="0.583333"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M13.5417 15.125C13.7028 15.125 13.8333 14.9944 13.8333 14.8334C13.8333 14.6723 13.7028 14.5417 13.5417 14.5417C13.3806 14.5417 13.25 14.6723 13.25 14.8334C13.25 14.9944 13.3806 15.125 13.5417 15.125Z"
                stroke="#757575"
                stroke-width="0.583333"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 9H9.16667L9.94833 12.9054C9.975 13.0397 10.0481 13.1603 10.1547 13.2462C10.2613 13.332 10.3948 13.3776 10.5317 13.375H13.3667C13.5035 13.3776 13.637 13.332 13.7436 13.2462C13.8503 13.1603 13.9233 13.0397 13.95 12.9054L14.4167 10.4583H9.45833"
                stroke="#757575"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <rect x="1" y="3.50879" width="21" height="14.8912" rx="1" stroke="#757575" stroke-width="2" />
              <path d="M1 2C1 1.44772 1.44772 1 2 1H21C21.5523 1 22 1.44771 22 2V5.86511H1V2Z" fill="white" />
              <line x1="3.98074" y1="3.30619" x2="6.00269" y2="3.30619" stroke="#757575" stroke-width="1.26372" stroke-linecap="round" />
              <line x1="8.53005" y1="3.30619" x2="19.0189" y2="3.30619" stroke="#757575" stroke-width="1.26372" stroke-linecap="round" />
              <path d="M1 2C1 1.44772 1.44772 1 2 1H21C21.5523 1 22 1.44771 22 2V5.86511H1V2Z" stroke="#757575" stroke-width="2" />
            </svg>
            <span class="ml-2">On payment pages</span>
          </div>
          <vs-row class="mb-2">
            <vs-checkbox
              class="text-gray-font mx-0 text-base checkbox-space"
              v-model="customField.displayToCustomer"
              :vs-value="true"
              :disabled="customField.editableByCustomer || customField.requiredToSubmit"
              @change="changeCheckboxValue(customField)"
              >Viewable by customer</vs-checkbox>
          </vs-row>
          <vs-row class="mb-2">
            <vs-checkbox
              class="text-gray-font mx-0 text-base checkbox-space"
              v-model="customField.editableByCustomer"
              :vs-value="true"
              :disabled="customField.requiredToSubmit"
              @change="changeCheckboxValue(customField)"
              >Editable by customer</vs-checkbox>
          </vs-row>
          <vs-row class="mb-2">
            <vs-checkbox
              class="text-gray-font mx-0 text-base checkbox-space"
              v-model="customField.requiredToSubmit"
              :vs-value="true"
              @change="changeCheckboxValue(customField)"
              >Required to submit payment</vs-checkbox>
          </vs-row>
        </div>
      </div>
      <!---- field configuration ends here ---->
      <div class="button-section flex items-center mb-0">
        <vs-button v-if="editPopUp" @click="deleteWarn" class="only-border-btn" v-round>Delete</vs-button>
        <vs-button
          :disabled="buttonDisabled"
          size="large"
          class="mx-8"
          @click="saveCustomField()"
          v-round
          >{{ editPopUp ? "Update" : "Save" }}</vs-button>

        <a class="mid-blue underline" @click="onCancel">Cancel</a>
      </div>
    </vs-popup>

    <delete-popup
      :popup-active="popupDelActive"
      :warningContent="warningContent"
      :deleteWhat="deleteWhat"
      :buttonText="buttonText"
      :found="warningFound"
      @handleDeleteClick="delActions"
    ></delete-popup>
    <!-- popup ends -->

    <!-- custom fields list card ends here -->
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import { mapActions } from "vuex";
import { Validator } from "vee-validate";
import DeletePopup from "@/views/components/DeletePopup";
import {
  Edit2Icon,
  Trash2Icon,
  PlusIcon,
  CalendarIcon,
} from "vue-feather-icons";
const dictionary = {
  en: {
    attributes: {
      label: "field name",
    },
  },
};
Validator.localize(dictionary);

export default {
  components: {
    PlusIcon,
    Edit2Icon,
    Trash2Icon,
    CalendarIcon,
    DeletePopup,
    DatePicker,
  },
  props: ["customFieldValue"],
  data() {
    return {
      randomKey: 0,
      edit: require("@/assets/images/edit.png"),
      text: require("@/assets/images/icons/text.png"),
      currency: require("@/assets/images/icons/currency.png"),
      fileupload: require("@/assets/images/icons/fileupload.png"),
      dropdown: require("@/assets/images/icons/dropdown.png"),
      datepicker: require("@/assets/images/icons/datepicker.png"),
      number: require("@/assets/images/icons/number.png"),
      partnerId: "",
      value: {},
      option: {},
      user: "",
      customFieldsList: [],
      idForEdit: "",
      typeForCss: "text",
      label: "",
      oldLabel: "",
      dropdownSelectFields: [],
      dropdownField: "",
      type: "",
      popupActivo: false,
      editPopUp: false,
      isChecked: false,
      productCustomFieldId: "",
      customField: {
        reqToSendRequest: false,
        displayToCustomer: false,
        editableByCustomer: false,
        editableByMerchant: false,
        requiredToSubmit: false,
        displayToMerchantAfter: false,
        editableByMerchantAfter: false,
        displayToCustomerAfter: false,
        editableByCustomerAfter: false,
      },
      popupDelActive: false,
      warningFound: false,
      warningContent: "Are you sure you want to permanently delete this custom field? You can’t undo this action.",
      deleteWhat: "field",
      buttonText: "Delete Field",
      datepickerPreview: "",
      activateButton: false,
      optionsforDropdown: [],
    };
  },

  computed: {
    validateForm() {
      return !this.errors.any();
    },

    buttonDisabled() {
      if (this.editPopUp) {
        if (this.type =="dropdown") {
          if (this.label && this.dropdownSelectFields.length > 0 && this.dropdownSelectFields[0].length > 1) {
            return false;
          }
        } else {
           return !this.activateButton && !this.isChecked;
        }
      }

      this.optionsforDropdown = Object.values(this.option);

      if (this.label && this.isChecked) {
        if (this.type =="dropdown") {
          if (this.editPopUp === false && this.optionsforDropdown.length > 0 && this.dropdownSelectFields.length > 0 && this.optionsforDropdown[0].length > 1) {
            return false;
          }
          return true;
        }
        return false;
      }
      return true;
    },
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
    if (this.user.userType == "admin") {
      this.partnerId = this.user._id;
    }
    if (this.user.userType == "staff") {
      this.partnerId = this.user.partnerId;
    }
    this.customFieldsList = this.customFieldValue;
  },

  methods: {
    ...mapActions("customField", [
      "createCustomField",
      "fetchCustomFieldsByPartnerId",
    ]),

    atLeastOneChecked() {
      return Object.values(this.customField).some((el) => el);
    },

    activate(el) {
      if (el !== this.type) {
        //activate the disabled button
        this.activateButton = true;
      }
      this.typeForCss = el;
      this.type = el;
    },

    addDropdownOptions() {
      this.value = Object.entries(this.option).map((el) => el[1]);
      this.dropdownSelectFields.push(this.value);
    },

    // delete
    deleteDropdownField(detail, key) {
      this.dropdownSelectFields.splice(key, 1);
      delete this.option[key];
    },

    onCancel() {
      this.randomKey++;
      this.popupActivo = false;
      this.activateButton = false;
      this.label = "";
      this.type = "";
      this.productCustomFieldId = "";
      this.dropdownSelectFields = [];
      this.clearFields();
    },

    showMessage(title = "Successful", message = "Task completed", color = "success", icon = "icon-check-circle") {
      this.$vs.notify({
        title: title,
        text: message,
        color: color,
        iconPack: "feather",
        position: "top-right",
        icon: icon,
      });
    },

    createCustomField() {
      this.clearFields();
      this.randomKey++;
      this.popupActivo = true;
      this.editPopUp = false;
      this.isChecked = false;
      this.typeForCss = "text";
      this.activateButton = false;
    },

    clearFields() {
      this.editPopUp = false;
      this.label = "";
      this.productCustomFieldId = "";
      this.type = "text";
      this.dropdownSelectFields = [];
      this.option = {};
      for (var key in this.customField) {
        this.customField[key] = false;
      }
    },

    openEditGroup(selectedCustomField, indextr) {
      this.randomKey++;
      this.popupActivo = true;
      this.activateButton = false;
      this.editPopUp = true;
      this.idForEdit = selectedCustomField._id;
      this.label = selectedCustomField.label;
      this.oldLabel = selectedCustomField.label;
      this.type = selectedCustomField.type;
      this.productCustomFieldId = selectedCustomField.customFieldId;
      this.typeForCss = selectedCustomField.type;
      this.dropdownSelectFields = selectedCustomField.dropdownSelectFields;
      this.option = this.dropdownSelectFields;
      this.customField = _.pick(selectedCustomField, Object.keys(this.customField));
    },

    // change checkbox
    changeCheckboxValue(customField) {
      this.isChecked = this.atLeastOneChecked();
      if (this.customField.reqToSendRequest) {
        this.customField.editableByMerchant = true;
      }

      if (this.customField.requiredToSubmit) {
        this.customField.editableByCustomer = true;
        this.customField.displayToCustomer = true;
      }

      if (this.customField.editableByCustomer) {
        this.customField.displayToCustomer = true;
      }

      this.$emit("savedCustomField", this.customFieldsList);
    },

    deleteWarn() {
      this.popupDelActive = true;
      this.popupActivo = false;
    },

    deleteCustomField() {
      const objIndex = this.customFieldsList.findIndex((obj) => obj._id == this.idForEdit);
      this.customFieldsList.splice(objIndex, 1);
      this.randomKey++;
      this.popupActivo = false;
      this.editPopUp = false;
      this.label = "";
      this.label = "";
      this.type = "";
      this.dropdownSelectFields = [];
      this.$emit("savedCustomField", this.customFieldsList);
    },

    saveCustomField() {
      const firstBits = Math.floor(1000 + Math.random() * 9000);
      const secondBits = Math.floor(1000 + Math.random() * 9000);
      const fieldId = `CF-${firstBits}-${secondBits}`;
      try {
        const newField = {
          label: this.label,
          type: this.type,
          partnerId: this.partnerId,
          editableByMerchant: this.customField.editableByMerchant,
          reqToSendRequest: this.customField.reqToSendRequest,
          displayToCustomer: this.customField.displayToCustomer,
          editableByCustomer: this.customField.editableByCustomer,
          requiredToSubmit: this.customField.requiredToSubmit,
        };
        if (this.type == "dropdown") {
          newField.dropdownSelectFields = Object.entries(this.option).map((el) => el[1]);
        }

        if (this.editPopUp == false) {
          newField._id = fieldId;
          newField.customFieldId = fieldId;
          this.customFieldsList.push(newField);
        }

        if (this.editPopUp == true) {
          newField._id = this.idForEdit;
          newField.customFieldId = this.productCustomFieldId;

          const objIndex = this.customFieldsList.findIndex((obj) => obj._id == this.idForEdit);

          // save all checkboxes values
          Object.assign(this.customFieldsList[objIndex], _.omit(newField, ["_id"]));

          if (this.customFieldsList[objIndex].type == "dropdown") {
            this.customFieldsList[objIndex].dropdownSelectFields = Object.entries(this.option).map((el) => el[1]);
          }

          this.activateButton = false;
        }

        this.$emit("savedCustomField", this.customFieldsList);

        this.randomKey++;
        this.popupActivo = false;
        this.label = "";
        this.type = "";
        this.isChecked = false;
        this.dropdownSelectFields = [];
        this.showMessage("Successful", "Custom field has been created successfully.", "success", "icon-check-circle");
      } catch (ex) {
        this.showMessage("Custom fields", "We are unable to process your request at this time.", "danger", "icon-alert-circle");
      }
    },

    delActions(event) {
      switch (event) {
        case "delete":
          this.popupDelActive = false;
          this.deleteCustomField();
          break;
        case "cancel":
          this.popupDelActive = false;
          this.popupActivo = true;
          break;
        default:
          this.popupDelActive = false;
      }
    },

    handleLabel() {
      if ( this.editPopUp ) {
        this.activateButton = this.oldLabel !==  this.label;
      }
    }
  },
};
</script>
